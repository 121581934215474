const menu = {
    button: {
        open: document.querySelector(".nav-mobile__button"),
        close: document.querySelector(".nav-mobile__close"),
    },
    class: "nav-mobile__active",
    overlay: document.querySelector(".nav-mobile__overlay"),
    nav: document.querySelector(".nav-mobile"),
    dropDowns: document.querySelectorAll(".nav__item--drop"),
};

export function closeMenu() {
    menu.nav.classList.remove(menu.class);
    menu.overlay.removeAttribute("style");
    document.body.removeAttribute("style");
}

export function openMenu() {
    menu.nav.classList.add(menu.class);
    menu.overlay.style.left = 0;
    document.body.style.overflow = "hidden";
}

export function openSubMenu() {
    const subMenu = this.children[1];
    subMenu.classList.toggle("d-none");
}

export default menu;
